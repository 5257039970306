import { stringReplacement } from '@mechhive/helpers';
import { Image, ImageFormat, ImageLoading } from '@mechhive/react';
import { Link } from '@remix-run/react';
import { useLanguage } from '~/hooks/useLanguage';
import { useLocalePath } from '~/hooks/useLocalePath';
import { useTranslate } from '~/hooks/useTranslate';
import { LANGUAGE_DISPLAY_NAME } from '~/locale';
import { ChangeLanguageModal } from '~/modals/ChangeLanguageModal';
import { useModalStore } from './modal/ModalStore';
import { Icon, IconName } from './ui/Icon';
import clsx from 'clsx';
import { useState } from 'react';

type CollapsableMenuProps ={
  label: string;
  items: Array<{
    to: string;
    label: string;
  }>
}

const CollapsableMenu = ( { items, label }: CollapsableMenuProps  ) => {
  const [ isOpen, setIsOpen ] = useState<boolean>( false );

  return (
    <div className={ 'border-b-2 border-[#64548D]/30 md:border-b-0' }>
      <h4
        className="py-5 md:pt-0 text-[#ABB0C7] hover:text-white md:pointer-events-none cursor-pointer text-base font-semibold flex flex-row items-center justify-between"
        onClick={ () => {
          setIsOpen( !isOpen );
        } }>
        { label }
        <Icon
          name={ IconName.ChevronDown }
          className={ 'icon-size-5 md:hidden' } 
        />
      </h4>
      <ul className={ clsx( {
        'hidden md:block': !isOpen
      } ) }>
        { items.map( ( { label, to }, index ) => {
          return (
            <li
              key={ index }
              className={ 'flex group' }>
              <Link
                to={ to }
                className={ 'py-[15px] group-last:pb-5 text-[#ABB0C7] text-sm hover:text-white' }>
                { label }
              </Link>
            </li>
          )
        } ) }
      </ul>
    </div>
  )
}


export const Footer = () => {
  const localePath = useLocalePath();
  const t = useTranslate();
  const language = useLanguage();

  const changeLanguageModalStore = useModalStore();

  return (
    <>
      <div className={ 'pb-[50px] flex flex-col w-full' }>
        <div className={ 'md:flex md:flex-row md:justify-between md:border-b-2 md:border-[#64548D]/30' }>
          <div className={ 'flex flex-col gap-10 md:gap-20 border-b-2 md:border-0 border-[#64548D]/30 pb-5' }>
            <Image 
              provider='images'
              src={ '/rewarble-logo.svg' }
              alt={ 'Rewarble Logo' }
              className={ 'opacity-80' }
              width={ 32 }
              formats={ [
                ImageFormat.WebP,
                ImageFormat.PNG
              ] }
              sizes={ [{
                width: 32
              }] }
              dprVariants={ [1,2] }
            />            
            <button
              className='flex gap-2.5 items-center group'
              onClick={ () => {
                changeLanguageModalStore.show()
              } }>
              <Image
                provider='images'
                src={ `/flags/${language.toUpperCase()}.svg` }
                className={ 'h-auto w-6 object-contain border border-[#FFFFFF]/50 rounded-sm cursor-pointer' }
                sizes={ [
                  { width: 24 }
                ] }
                alt={ `${language.toUpperCase()} flag` }
                loading={ ImageLoading.Lazy }
                dprVariants={ [2] }
              />
              <div className='group-hover:text-white'>{LANGUAGE_DISPLAY_NAME[ language ]}</div>
            </button>
          </div>
          <div className={ 'md:flex md:flex-row md:gap-[100px]' }>
            <CollapsableMenu
              items={ [
                { label: t( 'FOOTER_SUPPORT' ), to: localePath( '/support' ) },
                { label: t( 'FOOTER_BRANDS' ), to: localePath( '/brands' ) },
                { label: t( 'FOOTER_ABOUTUS' ), to: localePath( '/about-us' ) },
                { label: t( 'FOOTER_FEES' ), to: localePath( '/fees' ) },
              ] }
              label={ t( 'FOOTER_SECTION_TITLE_NAVIGATION' ) }
            />
            <CollapsableMenu
              items={ [
                { label: t( 'FOOTER_GENERAL' ), to: localePath( '/terms-and-conditions' )  },
                { label: 'Anonimy Visa', to: localePath( '/terms/visa-anonimy' ) },
                { label: 'Premium Visa', to: localePath( '/terms/visa-premium' ) },
                { label: 'Premium Mastercard', to: localePath( '/terms/mastercard-premium' ) },
              ] }
              label={ t( 'FOOTER_SECTION_TITLE_TERMS_AND_CONDITIONS' ) }
            />
            <CollapsableMenu
              items={ [
                { label: t( 'FOOTER_RETURNANDCANCELLATIONS' ), to: localePath( '/returns-cancellations' ) },
                { label: t( 'FOOTER_AML' ), to: localePath( '/aml' ) },
                { label: t( 'FOOTER_PRIVACY' ), to: localePath( '/privacy' )  },
              ] }
              label={ t( 'FOOTER_SECTION_TITLE_OTHER' ) } />
          </div>
        </div>
        <p className='text-[#ABB0C780] text-sm pt-5'>
          { stringReplacement( t( 'FOOTER_COPYRIGHT' ), {
            'year': new Date().getFullYear()
          } ) }
        </p>
      </div>
      <ChangeLanguageModal 
        store={ changeLanguageModalStore }
      />
    </>
  )
}
