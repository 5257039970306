import { Link } from '@remix-run/react';
import type { Price } from '../Price';
import { Icon, type IconName } from './Icon';

type HamburgerMenuItemProps = {
    label?: string | React.ReactElement<typeof Price>;
    iconName?: IconName;
    to?: string;
    signedIn?: boolean;
    badge?: boolean;
    onClick?: () => void;
  }
  
export const HamburgerMenuItem = ( { label, iconName, to, onClick, badge }: HamburgerMenuItemProps ) => {
  return (
    <Link
      className='w-full flex flex-row gap-4 items-center'
      to={ to ?? '' }
      onClick={ onClick }>
      { iconName &&
        <div className={ 'relative' }>
          <Icon
            className='icon-size-5'
            name={ iconName }/>
          { badge &&
            <div className={ 'absolute w-2.5 h-2.5 bg-[#E16B45] top-0 -right-1 rounded-full' } />
          }
        </div>
      }
      { label }
    </Link>
  );
}