import { isStringEmpty } from '@mechhive/helpers';
import { useFingerprint } from '@mechhive/react';
import { useLocation, useParams } from '@remix-run/react';
import { useEffect, useState } from 'react';
import { createInternalApi } from '~/api/internal.api';
import { usePrevious } from '@uidotdev/usehooks';

export const UserRequest = ( ) => {
  const location = useLocation();
  const previousLocation = usePrevious( location );
  const { visitorId } = useFingerprint();
  const [ initialized, setInitialized ] = useState<boolean>( false );
  const internalApi = createInternalApi();
  const params = useParams();

  const sendUserRequest = () => {
    internalApi.user.request( {
      deviceFingerprint: visitorId,
      params: params,
      target: location.pathname
    } )
  }
  
  useEffect( () => {
    if ( previousLocation && previousLocation.pathname !== location.pathname ) {
      sendUserRequest();
    }
  }, [ location ] );

  useEffect( () => {
    if ( initialized === false && location && !isStringEmpty( visitorId ) ) {
      sendUserRequest();
      setInitialized( true );
    }
  }, [ location, visitorId] );

  return null;
}
