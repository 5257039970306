import { Image, ImageLoading } from '@mechhive/react';
import clsx from 'clsx';
import { createInternalApi } from '~/api/internal.api';
import type { CustomModalProps } from '~/components/modal/Modal';
import { Modal } from '~/components/modal/Modal';
import { ModalContent } from '~/components/modal/ModalContent';
import { ModalTitle } from '~/components/modal/ModalTitle';
import { useLanguage } from '~/hooks/useLanguage';
import { useTranslate } from '~/hooks/useTranslate';
import { LANGUAGE_DISPLAY_NAME, SUPPORTED_LANGUAGES } from '~/locale';

type ChangeLanguageModalProps = CustomModalProps & { };

export const ChangeLanguageModal = ( { store }: ChangeLanguageModalProps ) => {
  const internalApi = createInternalApi();
  const language = useLanguage();
  const t = useTranslate();

  const changeLanguage = async ( language ) => {
    await internalApi.account.patchLanguage( language );

    store.close();
    window.location.reload();
  }

  return (
    <Modal store={ store }>
      <ModalTitle>{t( 'CHANGE_LANGUAGE_MODAL_TITLE' )}</ModalTitle>
      <ModalContent>
        <div className={ 'flex flex-col items-center' }>
          <div className={ 'grid grid-cols-2 w-full lg:w-[590px] gap-[30px]' }>
            { SUPPORTED_LANGUAGES.map( ( lang ) => {
              const isSelected = lang === language;
              const displayName = LANGUAGE_DISPLAY_NAME[ lang ];

              return (
                <button
                  type={ 'button' }
                  key={ lang }
                  className={ clsx(
                    ' border rounded-[10px] px-[30px] py-5',
                    {
                      'bg-[#17193F99] border-[#756BDE]': isSelected,
                      'bg-[#17193F4D] border-[#FFFFFF1A] hover:bg-[#17193F99] hover:border-[#756BDE] cursor-pointer': !isSelected
                    }
                  ) }
                  onClick={ () => changeLanguage( lang ) }>
                  <div className={ 'flex flex-col lg:flex-row items-center gap-3' }>
                    <div>
                      <div className={ 'relative' }>
                        <div className={ 'border border-white/50 absolute inset-0' } />
                        <Image
                          provider='images'
                          src={ `/flags/${lang.toUpperCase()}.svg` }
                          className={ 'h-auto w-6 object-contain rounded-sm cursor-pointer' }
                          sizes={ [
                            { width: 24 }
                          ] }
                          alt={ `${language.toUpperCase()} flag` }
                          loading={ ImageLoading.Lazy }
                          dprVariants={ [2] }
                        />
                      </div>
                    </div>
                    <span className={ 'text-sm' }>{ displayName }</span>
                  </div>
                </button>
              )
            } )}
          </div>
        </div>
      </ModalContent>
    </Modal>
  )
}